// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-post-page-js": () => import("/opt/build/repo/src/templates/PostPage.js" /* webpackChunkName: "component---src-templates-post-page-js" */),
  "component---src-templates-product-page-js": () => import("/opt/build/repo/src/templates/ProductPage.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-video-page-js": () => import("/opt/build/repo/src/templates/VideoPage.js" /* webpackChunkName: "component---src-templates-video-page-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-algorithm-js": () => import("/opt/build/repo/src/pages/algorithm.js" /* webpackChunkName: "component---src-pages-algorithm-js" */),
  "component---src-pages-community-js": () => import("/opt/build/repo/src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-confirmation-success-js": () => import("/opt/build/repo/src/pages/confirmation-success.js" /* webpackChunkName: "component---src-pages-confirmation-success-js" */),
  "component---src-pages-demo-js": () => import("/opt/build/repo/src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menu-js": () => import("/opt/build/repo/src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-templates-js": () => import("/opt/build/repo/src/pages/templates.js" /* webpackChunkName: "component---src-pages-templates-js" */),
  "component---src-pages-tools-js": () => import("/opt/build/repo/src/pages/tools.js" /* webpackChunkName: "component---src-pages-tools-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

